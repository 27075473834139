


#piechart-controls {
    text-align: center;

}

#piechart-instructions {
    display: inline-block;
    width: 600px;
    text-align: center;
}


.pietable {
    border-collapse: collapse;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    display: inline-block;

}


.adjust-button {
    width: 50px;
    height: 50px;
    font-size: 50px;
    background-color: #dddddd;
    line-height:50px;
    text-align: center;
    display: inline-block;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 1px;
}

var {
    width: 40px;
    font-size: 30px;
    font-weight: bold;

}

