/*
 ===============
 Fonts
 ===============
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");

/*
===============
Variables
===============
*/
:root {

    /* FMD Text: primary main color */


    /* FMD Gold: primary main color */
    --clr-fmd-gold: #f4c257;
    --clr-fmd-dark-gold: #ffaf00;
    --clr-fmd-light-gold: #f4db9b;

    --clr-fmd-light-grey: #808080;
    --clr-fmd-dark-grey: #606060;

    --clr-fmd-blue: hsl(210, 22%, 49%);
    --clr-fmd-light-blue: hsl(208, 78%, 93%);

    --clr-fmd-error-red: #990033;
    --clr-fmd-error-pink: #dd88bb;


    --clr-grey-1: hsl(210, 61%, 16%);
    --clr-grey-2: hsl(210, 39%, 23%);
    --clr-grey-3: hsl(210, 34%, 30%);
    --clr-grey-4: hsl(210, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(210, 23%, 60%);
    --clr-grey-7: hsl(210, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(210, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);


    --clr-body-text: #606060;


    --clr-nav-links: #606060;

    --clr-body-background: #fBfDFF;
    --clr-white: #fff;
    --clr-black: #222;
    --ff-primary: "Roboto", sans-serif;
    --ff-secondary: "Open Sans", sans-serif;
    --transition: all 0.3s linear;
    --spacing: 0.0rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    --max-width: 1170px;
    --fixed-width: 620px;

}

/*
===============
Global Styles
===============
*/
*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--ff-primary);
    background: var(--clr-body-background);
    color: var(--clr-body-text);
    line-height: 1.5;
    font-size: 0.875rem;
}


h1,
h2,
h3,
h4 {
    letter-spacing: 0rem;
    line-height: 1.25;
    margin-bottom: 0.3rem;
    font-family: var(--ff-primary);

}

h1 {
    font-size: 1.4rem;
}

h2 {
    font-size: 1.1rem;
}

h3 {
    font-size: 1rem;
    margin-bottom: 0rem;
}

h4 {
    font-size: 0.875rem;
}


p {
    margin-bottom: 0.2rem;
    text-align: justify;
    padding: 1px 2px 5px 2px; /* must align with fields that are in mainTable */
}


ul {
    list-style-type: inherit;
    padding-left: 2rem;
}


table, th, td {
    border-collapse: separate;
    border: solid var(--clr-fmd-light-grey) 1px;
    border-radius: 6px;
    -moz-border-radius: 6px;
}

.highlighted-cell {
    background-color: var(--clr-fmd-light-gold);
}


input, textarea {
    width: 100%;
    font-size: 18px;
    color: var(--clr-fmd-dark-grey);
    border: 1px solid var(--clr-fmd-blue);
    background-color: var(--clr-fmd-light-blue);
    border-radius: 8px;
    overflow: scroll;
    padding-left: 4px;
    padding-right: 4px;
}


label {
    font-size: 0.85rem;
    font-weight: bold;
    padding-bottom: 30px;
}

input::placeholder {
    color: var(--clr-fmd-dark-grey);
}

button {
    /*height: 31px;*/
    font-weight: bold;
    margin: 4px 1px;
    padding: 5px 15px;
    background-color: var(--clr-fmd-gold);
    font-size: 1rem;
    text-align: center;
    border: 0;
    box-sizing: border-box;
    color: white;
    border-radius: 8px;
    vertical-align: middle;
}

button:hover {
    cursor: pointer;
    background-color: var(--clr-fmd-dark-gold);
}


a {
    color: var(--clr-fmd-dark-grey);
}

a:link {
    text-decoration: underline;
}

a:visited {
    text-decoration: underline;
}

a:hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--clr-fmd-dark-gold);
}

a:active {
    text-decoration: none;
    color: var(--clr-fmd-dark-gold);
}


.outerDiv {
    margin: auto;
    width: 97%;
    border: 3px solid var(--clr-fmd-gold);
    padding: 8px 1px 26px;
    border-radius: 16px;
    align-content: center;
    text-align: center;
}

.mainTable, main-table-highlighted {
    margin: auto;
    border: 0px;
}

.main-table-highlighted {
    margin: auto;
    width: 98%;
    border: 3px solid var(--clr-fmd-dark-gold);
    padding: 5px 3px;
    border-radius: 8px;
    align-content: center;
    text-align: center;

}

.inputboxerror {
    border: 1px solid var(--clr-fmd-error-red);
    background-color: var(--clr-fmd-error-pink);
}

.errormessage {
    font-size: 0.8rem;
    color: var(--clr-fmd-error-red);
    text-align: left;
    white-space: pre-line;
    /* helps with react apps that can't conditionally put line breaks in.
       It means \n will be respected and used as a break instead of a <br> */
}

.round-tappable-image {
    cursor: pointer;
    textAlign: right;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    paddingBottom: 0;
    vertical-align: bottom;

}

.field-compulsory {
    font-weight: bold;
}

.field-compulsory-indicator {
    font-weight: bold;
    font-size: 0.5rem;
    color: var(--clr-fmd-error-red);

}


/*
===============
Auto suggestions
===============
*/

.autosuggestiondiv {
    padding-top: 1px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
    position: absolute;
    z-index: 1;

}

.autosuggestionbox {
    font-size: 18px;
    width: 100%;
    border: 1px solid var(--clr-fmd-blue);
    background-color: var(--clr-fmd-light-gold);
    color: var(--clr-black);
    border-radius: 8px;
    box-sizing: border-box;
    text-decoration: none;
    padding-bottom: 1px;
    padding-right: 5px;
    padding-left: 5px;
    line-height: 27px;
    text-align: left;
}

.autosuggestionbox:hover {
    background-color: var(--clr-fmd-gold);
    color: var(--clr-black);
    cursor: pointer;
    font-weight: normal;

}

/* should be identical to autosuggestionbox.hover (including it's inherited stuff) */
.activeautosuggestion {
    font-size: 18px;
    text-decoration: none;
    width: 100%;
    border: 1px solid var(--clr-fmd-blue);
    border-radius: 8px;
    box-sizing: border-box;
    padding-bottom: 1px;
    padding-right: 4px;
    padding-left: 4px;
    line-height: 27px;
    background-color: var(--clr-fmd-gold);
    color: var(--clr-black);
    cursor: pointer;
    text-align: left;
}

.autosuggestionerrorbox {
    font-size: 18px;
    color: var(--clr-fmd-light-blue);
    width: 100%;
    border: 1px solid var(--clr-fmd-blue);
    background-color: var(--clr-fmd-error-red);
    border-radius: 8px;
    box-sizing: border-box;
    text-decoration: none;
    padding-bottom: 1px;
    padding-right: 5px;
    padding-left: 5px;
    line-height: 27px;
    text-align: left;
}


.autosuggestionerrorboxlink {
    font-size: 18px;
    color: var(--clr-fmd-light-blue);
    width: 100%;
    text-decoration: none;
    padding-bottom: 1px;
    padding-right: 5px;
    padding-left: 5px;
    line-height: 27px;
    text-align: left;
}


.autosuggestionplaceholder {
    color: var(--clr-fmd-light-grey);
    padding-top: 3px;
    padding-right: 4px;
    padding-left: 4px;
    font-style: italic;
    text-align: left;
}


/*
===============
Float Spinner
===============
*/

.is-disabled {
    transition: ease-in-out 0.5s;
    opacity: 0.5;
    pointer-events: none;
}

.float-spinner {
    position: fixed;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
}


/*
===============
Popup Page
===============
*/
.popupstyle {
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    height: 250%;
    padding: 80px 5px;
    background-color: var(--clr-fmd-light-gold);
}

.popupstyle_content {
    margin: auto;
    background-color: white;
    top: 50px;
    width: 100%;
    padding: 5px 30px 30px 30px;
    border-radius: 8px;
    border: 2px solid black;
}

.closePopupX {
    font-size: 50px;
    color: Black;
    float: right;
    font-weight: 800;
    width: 100%;
    text-align: right;
    vertical-align: top;
    height: 50px;

}

.closePopupX:hover {
    color: var(--clr-fmd-dark-gold);
    cursor: pointer;
}


/*
===============
Tooltip
===============
*/
.tooltippopup {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual tooltippopup */
.tooltippopup .tooltippopuptext {
    visibility: hidden;
    width: 180px;
    background-color: #555;
    opacity: 0.9;
    color: #fff;
    text-align: center;
    border-radius: 7px;
    border: solid;
    border-width: 1px;
    border-color: #ffaf00;
    padding: 8px 20px 8px 20px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-bottom: 4px;
    margin-left: -378px;
}


.tooltippopup .tooltippopuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the tooltippopup */
.tooltippopup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.9;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.9;
    }
}


/*
===============
Navbar
===============
*/
nav {
    background: var(--clr-white);
    box-shadow: var(--light-shadow);
    z-index: 10;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem;
}

.nav-left {
    display: flex;
    padding: 0;
    align-items: center;

}

.nav-right {
    display: flex;
    padding: 0;
    align-items: center;
    text-align: right;
}

.nav-search {
    maxWidth: 240;
}


/*
See navMenu.js and the NavBarLoggedXX.js files to see how these styles interact to cause menu to be visible or not
 */

.color-burger-grey {
    color: var(--clr-fmd-dark-grey);
}

.nav-burger, .user-plus, .magnifying-glass {
    font-size: 1.5rem;
    color: var(--clr-fmd-gold);
    background: transparent;
    border-color: transparent;
    transition: var(--transition);
    cursor: pointer;
    padding: 4px;
    margin: 0px;
}

.nav-logo {
    height: 50px;
    width: 50px;
}

.nav-links {
    height: 0;
    overflow: hidden;
    transition: var(--transition);
    list-style-type: none;
    padding-left: 0; /* to override the default ul */

}

.nav-links a {
    color: var(--clr-nav-links);
    font-size: 1rem;
    text-align: right;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    display: block;
    padding: 0.5rem 2rem;
    transition: var(--transition);
    text-decoration: none;
}

.nav-links a:hover {
    color: var(--clr-fmd-gold);
    padding-right: 2.5rem;
    font-weight: bold;
}

.show-nav-dropdown {
    height: 18rem;
}

.show-nav-dropdown-loggedin {
    height: 21rem;
}

.behind-nav-pad {
    height: 65px;
}

.menuloggedin {
    background-color: var(--clr-fmd-light-gold);
}




/*
========================================================================================================================
========================================================================================================================
DESKTOP:
Styles overridden below for Desktop
========================================================================================================================
========================================================================================================================
*/
/* When the screen is big enough for the Desktop
/* (the min-width number should be identical to what is in the main fmd.css */
@media screen and (min-width: 900px) {
/*
===============
General - Desktop Screen
===============
*/
    .outerDiv {
        margin: auto;
        width: 80%;
        max-width: 1000px;
    }

    .popupstyle_content {
        width: 66%;
        max-width: 650px;
    }


/*
===============
Navbar - Desktop Screen
===============
*/
    .nav-center {
        max-width: 1000px; /* A little more than the above min width so we can see some expansion of the wide layout as we increase screen size */
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem;

    }

    .nav-logo {
        height: 70px;
        width: 70px;
    }


    .show-nav-dropdown {
        height: 15rem;
    }

    .show-nav-dropdown-loggedin {
        height: 21rem;
    }

    .nav-header {
        padding: 0;
    }

    .user-plus {
        display: none;
    }

    .nav-burger {
        display: none;
    }

    /* display : flex means the ul will be displayed in a line, not one below the other */
    .nav-links {
        height: auto;
        display: flex;
        flex-flow: wrap-reverse;
        margin-left: auto;

    }

    .nav-links a {
        padding: 0;
        margin: 0 0.5rem;
        margin-left: auto;
    }

    .nav-links a:hover {
        padding: 0;
        background: transparent;
    }

    .nav-links li {
        margin-left: auto;
    }

    /* divider for the flex container denotes one line of links from the other */
    .nav-center hr {
        width: 100%;
    }

    .menuloggedin {
        background-color: var(--clr-white);
        color: var(--clr-fmd-gold) !important;
    }

    .behind-nav-pad {
        height: 95px;
    }

}